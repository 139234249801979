import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from "styled-components"
import ClientLogo from "../components/ClientLogo"

import FWA from "../images/awards/fwa.svg"
import Awwwards from "../images/awards/awwwards.svg"
import CSSDA from "../images/awards/cssda.svg"

import { OutboundLink } from "gatsby-plugin-google-analytics"

const awardsLogos = {
  fwa: FWA,
  awwwards: Awwwards,
  cssda: CSSDA,
}

const AboutPage = ({ data }) => (
  <Layout layout="dark">
    <SEO
      title="Pierre Rossignol - Freelance Creative Technologist - About"
      description="I'm a freelance Creative Technologist based in New York, available for in-house work in the US and remote. I've been working on digital projects for more than 13 years."
      keywords={[
        `freelance developer`,
        `front-end developer`,
        `react developer`,
        `creative technologist`,
        `product developer`,
        `developer new york`,
      ]}
    />

    <MainWrapper>
      <SectionWrapper>
        <Title color="#FF763E">About me</Title>
        <TextContent>
          I'm a freelance Creative Technologist based in New York, available for
          in-house work in the US and remote. I've been working on digital
          projects for more than 15 years. I combine expert technical skills in
          front-end, back-end, product development and a deep knowledge and
          taste for other disciplines including design, marketing and strategy.
          I have extensive experience working within multidisciplinary teams and
          leading small technical squads. I bring a creative touch and motion to
          all my projects.
        </TextContent>
        <NetworkLinks>
          <NetworkLink
            href="mailto:pierre.rossignol.pro@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Email
          </NetworkLink>
          <NetworkLink
            href="https://www.linkedin.com/in/pierrerossignol"
            target="_blank"
            rel="noopener noreferrer"
          >
            Linkedin
          </NetworkLink>
          <NetworkLink
            href="https://workingnotworking.com/5252-pierre"
            target="_blank"
            rel="noopener noreferrer"
          >
            WorkingNotWorking
          </NetworkLink>
        </NetworkLinks>
      </SectionWrapper>
      <SectionWrapper>
        <Title color="#FF86A5">Stacks</Title>
        <TextContent>
          I'm used to adapt very quickly to any stack or team.{" "}
          <span>Here's a non-exhaustive list of my tech stacks of choice</span>
        </TextContent>
        <StacksList>
          <Stack>
            <StackLabel>Front-end</StackLabel>
            <StackDetail>
              ReactJS - ES6/7+ - JSS / SASS - Gatsby - GraphQL - Apollo - Jest -
              Webpack - Grunt/Gulp
            </StackDetail>
          </Stack>
          <Stack>
            <StackLabel>WebGL</StackLabel>
            <StackDetail>ThreeJS - PixiJS</StackDetail>
          </Stack>
          <Stack>
            <StackLabel>Back-end</StackLabel>
            <StackDetail>Node.js, Express, Koa, GraphQL, Apollo</StackDetail>
          </Stack>
          <Stack>
            <StackLabel>CMS</StackLabel>
            <StackDetail>Wordpress, Contentful, Prismic</StackDetail>
          </Stack>
          <Stack>
            <StackLabel>E-commerce</StackLabel>
            <StackDetail>
              Shopify (Themes - SDKs - APIs - Apps - Polaris)
            </StackDetail>
          </Stack>
          <Stack>
            <StackLabel>DevOps</StackLabel>
            <StackDetail>
              AWS (ECS - EBS - EC2 - S3 - CloudFront - CloudFormation -
              Lambda...)
            </StackDetail>
          </Stack>
        </StacksList>
      </SectionWrapper>

      <SectionWrapper>
        <Title color="#AD7CFF">Clients</Title>
        <TextContent>
          I've been fortunate to work with some amazing clients & agencies,
          between Paris and New York and on a wide range of both project types
          and industries.
        </TextContent>
        <ClientsGrid>
          <ClientLogo url="https://adidas.com" logo="adidas" name="Adidas" />
          <ClientLogo
            url="https://www.nespresso.com"
            logo="nespresso"
            name="Nespresso"
          />
          <ClientLogo url="https://www.ea.com" logo="ea" name="EA" />
          <ClientLogo
            url="https://www.warnerbros.com/"
            logo="warnerbros"
            name="Warner Bros"
          />
          <ClientLogo url="https://www.chloe.com" logo="chloe" name="Chloé" />
          <ClientLogo
            url="https://www.lancome.com/"
            logo="lancome"
            name="Lancôme"
          />
          <ClientLogo
            url="https://www.redbull.com"
            logo="redbull"
            name="Redbull"
          />
          <ClientLogo url="https://www.lego.com" logo="lego" name="LEGO" />
          <ClientLogo
            url="https://www.vw.com"
            logo="volkswagen"
            name="Volkswagen"
          />
          <ClientLogo url="https://www.kenzo.com" logo="kenzo" name="KENZO" />
          <ClientLogo url="https://b-reel.com" logo="breel" name="B-Reel" />
          <ClientLogo
            url="https://firstborn.com"
            logo="firstborn"
            name="firstborn"
          />
          <ClientLogo url="http://ddb.fr" logo="ddb" name="DDB" />
          <ClientLogo
            url="https://www.84paris.com"
            logo="les84"
            name="84.Paris"
          />
          <ClientLogo
            url="https://makemepulse.com"
            logo="mmp"
            name="Make me pulse"
          />
        </ClientsGrid>
      </SectionWrapper>

      <SectionWrapper>
        <Title color="#2DE4CC">Awards</Title>
        <TextContent>
          My projects have been published and recognized by other experts in the
          industry several times.
        </TextContent>
        <AwardsCount>
          <AwardColumn>
            <AwardLogo w={97} award="fwa" />
            <Count>x9</Count>
          </AwardColumn>
          <AwardColumn>
            <AwardLogo w={172} award="awwwards" />
            <Count>x12</Count>
          </AwardColumn>
          <AwardColumn>
            <AwardLogo w={130} award="cssda" />
            <Count>x3</Count>
          </AwardColumn>
        </AwardsCount>
        <AwardsListContainer>
          <Column>
            <ProjectTitle>Squarespace - Creative Dept.</ProjectTitle>
            <AwardsList>
              <Award>
                <AwardName>FWA —</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
              <Award>
                <AwardName>Awwwards —</AwardName>
                <AwardLabel>Mobile Excellence</AwardLabel>
              </Award>
              <Award>
                <AwardName>Awwwards —</AwardName>
                <AwardLabel>Honorable Mention</AwardLabel>
              </Award>
              <Award>
                <AwardName>CSSDA —</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
            </AwardsList>
            <ProjectTitle>Squarespace - Makers & Dreamers</ProjectTitle>
            <AwardsList>
              <Award>
                <AwardName>Awwwards —</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
              <Award>
                <AwardName>Awwwards —</AwardName>
                <AwardLabel>Developer Site of the Day</AwardLabel>
              </Award>
              <Award>
                <AwardName>Awwwards —</AwardName>
                <AwardLabel>Honorable Mention</AwardLabel>
              </Award>
              <Award>
                <AwardName>The Webby Awards —</AwardName>
                <AwardLabel>
                  2020 Honoree - Best Visual Design - Aesthetic
                </AwardLabel>
              </Award>
            </AwardsList>
            <ProjectTitle>Jules Bassoleil Portfolio</ProjectTitle>
            <AwardsList>
              <Award>
                <AwardName>FWA —</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
              <Award>
                <AwardName>Awwwards —</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
            </AwardsList>
          </Column>
          <Column>
            <ProjectTitle>Gravity WebGL Experience</ProjectTitle>
            <AwardsList>
              <Award>
                <AwardName>FWA x Adobe —</AwardName>
                <AwardLabel>Cutting Edge Award</AwardLabel>
              </Award>
              <Award>
                <AwardName>FWA —</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
              <Award>
                <AwardName>Awwwards —</AwardName>
                <AwardLabel>Site of the Month</AwardLabel>
              </Award>
              <Award>
                <AwardName>Awwwards —</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
              <Award>
                <AwardName>One Club —</AwardName>
                <AwardLabel>Merit - Interactive</AwardLabel>
              </Award>
            </AwardsList>
            <ProjectTitle>Ice and Sky</ProjectTitle>
            <AwardsList>
              <Award>
                <AwardName>FWA —</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
              <Award>
                <AwardName>Awwwards —</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
              <Award>
                <AwardName>CSSDA -</AwardName>
                <AwardLabel>Site of the Year</AwardLabel>
              </Award>
              <Award>
                <AwardName>CSSDA -</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
            </AwardsList>
            <ProjectTitle>Battlefield 360° Experience</ProjectTitle>
            <AwardsList>
              <Award>
                <AwardName>FWA —</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
            </AwardsList>
          </Column>
          <Column>
            <ProjectTitle>Volkswagen Electrique</ProjectTitle>
            <AwardsList>
              <Award>
                <AwardName>FWA -</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
              <Award>
                <AwardName>Awwwards -</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
              <Award>
                <AwardName>Awwwards -</AwardName>
                <AwardLabel>Honorable Mention</AwardLabel>
              </Award>
            </AwardsList>

            <ProjectTitle>Sonia By Pre-Spring</ProjectTitle>
            <AwardsList>
              <Award>
                <AwardName>FWA -</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
              <Award>
                <AwardName>FWA -</AwardName>
                <AwardLabel>Mobile of the Day</AwardLabel>
              </Award>
              <Award>
                <AwardName>Awwwards -</AwardName>
                <AwardLabel>Site of the Day</AwardLabel>
              </Award>
            </AwardsList>
          </Column>
        </AwardsListContainer>
      </SectionWrapper>
    </MainWrapper>
  </Layout>
)

const MainWrapper = styled.div`
  width: 100%;
  max-width: 1070px;
  margin: 100px auto 0 auto;

  @media (max-width: 1140px) {
    width: 100%;
    padding: 0 40px;
    max-width: none;
  }
  @media (max-width: 1024px) {
    margin: 70px auto 0 auto;
  }
  @media (max-width: 960px) {
    margin: 50px auto 0 auto;
  }
  @media (max-width: 780px) {
    padding: 0 30px;
  }
  @media (max-width: 640px) {
    margin: 30px auto 0 auto;
  }
  @media (max-width: 460px) {
    margin: 20px auto 0 auto;
  }
`

const SectionWrapper = styled.section`
  margin: 0 0 130px 0;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 150px;
  }

  @media (max-width: 1024px) {
    margin-bottom: 110px;
  }
  @media (max-width: 960px) {
    margin-bottom: 100px;
  }
  @media (max-width: 640px) {
    margin-bottom: 80px;
  }
  @media (max-width: 460px) {
    margin-bottom: 50px;
  }
`

const Title = styled.h2`
  font-size: 13px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 0 0 15px 0;
  color: ${props => props.color || "#FFF"};
`

const TextContent = styled.p`
  font-size: 36px;
  line-height: 50px;
  font-weight: 300;
  color: #fff;
  margin: 0;

  span {
    display: block;
  }

  @media (max-width: 1024px) {
    font-size: 32px;
    line-height: 48px;
  }
  @media (max-width: 960px) {
    font-size: 28px;
    line-height: 42px;
  }
  @media (max-width: 780px) {
    font-size: 26px;
    line-height: 38px;
  }
  @media (max-width: 640px) {
    font-size: 24px;
    line-height: 36px;
  }
  @media (max-width: 580px) {
    font-size: 22px;
    line-height: 34px;
  }
  @media (max-width: 560px) {
    span {
      display: inline;
    }
  }
  @media (max-width: 520px) {
    font-size: 20px;
    line-height: 32px;
  }
`

const NetworkLinks = styled.div`
  width: 100%;
  margin: 30px 0 0 0;
  display: flex;

  @media (max-width: 460px) {
    flex-direction: column;
  }
`

const NetworkLink = styled(OutboundLink)`
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 30px;
  margin-right: 30px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 640px) {
    font-size: 17px;
    margin-right: 25px;
  }
  @media (max-width: 460px) {
    font-size: 16px;
    margin-right: 0;
  }
`

const StacksList = styled.ul`
  width: 100%;
  margin: 40px 0;
`

const Stack = styled.li`
  display: flex;
  justify-content: flex-start;
  line-height: 30px;
  margin: 0 0 15px 0;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`

const StackLabel = styled.strong`
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.4);
  width: 150px;

  @media (max-width: 640px) {
    width: 100%;
    font-size: 17px;
  }
  @media (max-width: 460px) {
    font-size: 16px;
    line-height: 28px;
  }
`

const StackDetail = styled.span`
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  width: calc(100% - 150px);

  @media (max-width: 640px) {
    width: 100%;
    font-size: 17px;
  }
  @media (max-width: 460px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const ClientsGrid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 0;
  margin-top: 80px;

  @media (max-width: 1140px) {
    justify-content: flex-start;
    a {
      flex: 0 auto;
      margin: 0 30px 30px 0;
    }
  }
  @media (max-width: 960px) {
    a {
      margin: 0 15px 15px 0;
    }
  }
  @media (max-width: 760px) {
    a {
      margin: 0 10px 10px 0;
    }
  }
`

const AwardsCount = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 80px 0;

  @media (max-width: 960px) {
    margin: 50px 0;
  }

  @media (max-width: 640px) {
    margin: 30px 0;
    flex-direction: column;
  }
`

const Count = styled.strong`
  font-weight: 500;
  font-size: 28px;
  color: #fff;
  margin: 0 0 0 15px;

  @media (max-width: 640px) {
    font-size: 22px;
  }
`

const AwardColumn = styled.div`
  display: flex;
  line-height: 42px;
  flex: 1;

  @media (max-width: 960px) {
    flex: 0 auto;
  }

  @media (max-width: 640px) {
    line-height: 25px;
    margin-bottom: 25px;
  }
`

const AwardLogo = styled.a`
  width: ${props => props.w}px;
  height: 40px;
  display: block;
  background-image: url(${props => awardsLogos[props.award]});
  background-repeat: no-repeat;
  background-position: center left;

  @media (max-width: 640px) {
    width: 100px;
    height: 25px;
    margin-right: 20px;
    background-size: contain;
  }
`

const AwardsListContainer = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 960px) {
    flex-wrap: wrap;
  }
  @media (max-width: 640px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    flex: 50%;
  }
  @media (max-width: 640px) {
    flex: none;
  }
`

const ProjectTitle = styled.strong`
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.4);
  margin: 0 0 25px 0;
  display: block;

  @media (max-width: 640px) {
    margin-bottom: 10px;
  }
`

const AwardsList = styled.ul`
  margin: 0 0 50px 0;

  @media (max-width: 640px) {
    margin-bottom: 30px;
  }
`

const Award = styled.li`
  margin: 0;
`

const AwardName = styled.span`
  font-size: 16px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.4);
`

const AwardLabel = styled.span`
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.4);
  margin-left: 5px;
`

export default AboutPage
