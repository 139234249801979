import React from 'react'
import styled from 'styled-components'

import adidas from '../images/logos/adidas.svg'
import les84 from '../images/logos/84paris.svg'
import breel from '../images/logos/b-reel.svg'
import chloe from '../images/logos/chloe.svg'
import ddb from '../images/logos/ddb.svg'
import ea from '../images/logos/ea.svg'
import firstborn from '../images/logos/firstborn.svg'
import kenzo from '../images/logos/kenzo.svg'
import lancome from '../images/logos/lancome.svg'
import lego from '../images/logos/lego.svg'
import mmp from '../images/logos/mmp.svg'
import nespresso from '../images/logos/nespresso.svg'
import redbull from '../images/logos/redbull.svg'
import volkswagen from '../images/logos/volkswagen.svg'
import warnerbros from '../images/logos/warnerbros.svg'

import { OutboundLink } from 'gatsby-plugin-google-analytics';

const logos = {
  adidas,
  les84,
  breel,
  chloe,
  ddb,
  ea,
  firstborn,
  kenzo,
  lancome,
  lego,
  mmp,
  nespresso,
  redbull,
  volkswagen,
  warnerbros
}

export default function ClientLogo(props) {
  const { url, logo, name } = props
  return <LogoLink href={url} logo={logo}>{name}</LogoLink>
}

const LogoLink = styled(OutboundLink)`
  display: block;
  flex: none;
  background: rgb(255,255,255,0.05);
  width: 190px;
  height: 190px;
  text-decoration: none;
  font-size: 0;
  background-image: url(${props => logos[props.logo]});
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 25px;

  @media (max-width: 960px) {
    width: 160px;
    height: 160px;
  }
  @media (max-width: 760px) {
    width: 120px;
    height: 120px;
    background-size: 60% auto;
  }
  @media (max-width: 460px) {
    width: 100px;
    height: 100px;
    background-size: 60% auto;
  }
`